import React from 'react'
import Footer from './Footer'

import logo from '../assets/images/logo_new.png'

const Header = () => (
  <header id="header">
    <div className="inner">
      <a className="logo" href="/">
        <img src={logo} alt="Psychoterapia Mosty" style={{ maxWidth: '80%' }} />
      </a>

      <ul className="menu">
        <li>
          <a href="/mosty">MOSTY</a>
        </li>
        <li>
          <a href="/o-nas">Zespół</a>
        </li>
        <li>
          <a href="/psychoterapia-szkoleniowa">Psychoterapia szkoleniowa PTP</a>
        </li>
        <li>
          <a href="/seminaria-teoretyczno-superwizyjne">
            Seminaria teoretyczno-superwizyjne
          </a>
        </li>
        <li>
          <a href="/">Kontakt</a>
        </li>
      </ul>
    </div>
    <Footer />
  </header>
)

export default Header
