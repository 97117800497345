import React from 'react'

const Footer = () => (
  <div id="footer">
    <div className="inner">
      {/*<ul className="icons">*/}
      {/*  <li>*/}
      {/*    <a href="#" className="icon fa-twitter">*/}
      {/*      <span className="label">Twitter</span>*/}
      {/*    </a>*/}
      {/*  </li>*/}
      {/*</ul>*/}
      <ul className="copyright">
        <li>&copy; Psychoterapia Mosty</li>
      </ul>
    </div>
  </div>
)

export default Footer
